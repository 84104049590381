import React from 'react';
import Hero from '../components/Hero';
import img from '../images/404.jpg';
import 'normalize.css';
import Layout from '../components/Layout';

const NotFound = () => {
  return (
    <>
      <Layout>
        <Hero
          title={'Seems Like you are lost!'}
          subtitle={''}
          bgImage={img}
          isProducts={true}
          isHomePage={true}
          isLetsTalk={true}
          isFull='is-fullheight-with-navbar'
        />
      </Layout>
    </>
  );
};

export default NotFound;
